
































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import { reservationModule } from '@/store';
import { Notify } from 'quasar';
import { countryCodeList } from '@/shared/utils/countryCode';
import { EventBus } from '@/config/config';

@Component({
  components: {
    'alert-modal': () => import('@/components/popup/AlertModal.vue'),
    'tag-list': () => import('@/components/common/TagList.vue')
  }
})
export default class ReservationModal extends mixins(DataFormat) {
  @Prop()
  public type!: any;
  @Prop()
  public cartData!: any;
  @Prop()
  public selectLang!: any;

  public $refs!: {
    scrollWarp: HTMLElement;
  }

  public isModalClose: boolean = false;
  public user: string = '';
  public phoneNum: any = '';
  public requestNum: any = '';
  public radioChoice: any = '내국인';
  // 인증번호 발송 여부
  public requestNumComplete: boolean = false;
  // 인증번호 만료
  public requestNumExpire: boolean = false;
  // 인증번호 확인 여부
  public requestComplete: boolean = false;
  public time: any = {
    minute: 9,
    second: 59
  }
  public requestSendText: string = '인증번호 발송';
  public email: string = '';
  public countryCode: any = '국가번호';
  public countryList: any = countryCodeList;
  public radioAllergy: any = -1;
  public requestText: string = '';
  public height: number = 120;
  public allergyText: string = '';
  public allergyHeight: number = 120;
  public errorMessage: string = '';
  public cartAddData: any = {};
  public allergyKey: string = '';
  public nextDisable: boolean = true;
  public timeInterval: any = null;
  public selectShow: boolean = false;
  public topElHeight: number = 0;
  public loading: boolean = false;

  public modalOpened() {
    this.fetchReservationAddInfo();
    const el: any = document.querySelector('.certification-area');
    this.topElHeight = el.scrollHeight;
  }
  public back() {
    this.$modal.hide('reservationModal');
  }
  public fetchReservationAddInfo() {
    reservationModule
      .fetchReservationAddInfo({place_id: this.type.place_id})
      .then((res) => {
        if (res.data) {
          res.data.filter((row: any) => {
            if (row.title === '알러지') {
              this.allergyKey = row.key;
            }
          })
        }
      });
  }
  public inputHeight() {
    const textarea = document.querySelector('.text-area.reservation' as any);
    if (textarea) {
      textarea.addEventListener('input', () => {
        this.height = textarea.scrollHeight;
      });
    }
  }
  public inputAllergyHeight() {
    const textarea = document.querySelector('.text-area.allergy' as any);
    if (textarea) {
      textarea.addEventListener('input', () => {
        this.allergyHeight = textarea.scrollHeight;
      });
    }
  }
  public radioClick(e: any) {
    if (this.requestComplete) {
      e.preventDefault();
    }
  }
  public requestSendClick() {
    if (this.requestComplete) {
      return;
    }
    if (this.requestSendCheck) {
      this.requestNumComplete = false;
      const params = {
        receive_type: 'sms',
        receive_to: this.phoneNum,
        place_id: this.type.place_id
      }
      let message = 'SMS로 인증번호가 발송되었습니다.';
      if (this.radioChoice === '외국인') {
        params.receive_type = 'email';
        params.receive_to = this.email;
        message = '이메일로 인증번호가 발송되었습니다.';
      }
      reservationModule
        .postRequestNumSend(params)
        .then((res) => {
          if (res.data) {
            clearInterval(this.timeInterval);
            this.time.minute = 9;
            this.time.second = 59;
            Notify.create({
              message
            });
            this.requestSendText = '인증번호 재발송';
            this.requestNumComplete = true;
            this.validTimeCheck();
          }
        });
    }
  }
  public validTimeCheck() {
    this.timeInterval = setInterval(() => {
      this.time.second = this.time.second - 1;
      if (this.time.second === -1) {
        this.time.second = 59;
        this.time.minute = this.time.minute - 1;
      }
      if (this.time.second === 0 && this.time.minute === 0) {
        Notify.create({
          message: '인증번호 유효시간이 경과했습니다.'
        });
        clearInterval(this.timeInterval);
        this.requestNumExpire = true;
        this.requestNumComplete = false;
      }
    }, 1000);
  }
  public certifyConfirm() {
    if (this.requestNumComplete && this.requestNum.length === 5) {
      const params = {
        receive_to: this.phoneNum,
        certify_number: this.requestNum
      }
      if (this.radioChoice === '외국인') {
        params.receive_to = this.email;
      }
      reservationModule
        .putRequestNum(params)
        .then((res) => {
          console.log(res);
          if (res.error) {
            Notify.create({
              message: res.error.message
            });
          }
          if (res.data) {
            Notify.create({
              message: '인증이 완료되었습니다.'
            });
            this.requestNumComplete = false;
            this.requestComplete = true;
            clearInterval(this.timeInterval);
          }
        });
    }
  }
  public cartDataUpdate(data: any, key: any) {
    this.cartAddData[key] = data;
    this.$nextTick(() => {
      this.nextDisableFn();
    });
  }
  public nextDisableFn() {
    let blankCheck = false;
    for (let i = 0; i < Object.keys(this.cartAddData).length; i++) {
      const item: any = Object.values(this.cartAddData)[i];
      for (let j = 0; j < item.length; j++) {
        if (item[j].detail === '') {
          blankCheck = true;
        }
      }
    }
    if (this.requestComplete && this.user !== '' && this.radioAllergy !== -1 && this.reservationAddInfo.length) {
      if (this.radioAllergy === '1') {
        if (this.cartAddData[this.allergyKey] && this.cartAddData[this.allergyKey].length > 0) {
          if (blankCheck) {
            return this.nextDisable = true;
          }
          return this.nextDisable = false;
        } else {
          if (this.allergyText !== '') {
            return this.nextDisable = false;
          }
        }
        return this.nextDisable = true;
      } else if (this.radioAllergy === '0') {
        return this.nextDisable = false;
      }
    }
    // if (this.reservationAddInfo.length && this.requestComplete && this.user !== '') {
    //   return this.nextDisable = false;
    // }
    return this.nextDisable = true;
  }

  public getNextDisable() {
    if (this.requestComplete && this.user !== '' && this.radioAllergy !== -1 && this.reservationAddInfo.length) {
      if (this.radioAllergy === '1') {
        if (this.allergyText !== '' && this.cartAddData[this.allergyKey] && this.cartAddData[this.allergyKey].length < 1) {
          return false;
        }
        return true;
      } else if (this.radioAllergy === '0') {
        return false;
      }
    }
    // if (this.reservationAddInfo.length && this.requestComplete && this.user !== '') {
    //   return false;
    // }
    return true;
  }
  public cartPayment() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const cartAddData: any = {
      name: this.user,
      phone: this.phoneNum,
      is_foreigner: this.radioChoice === '내국인' ? 0 : 1,
      has_allergy: this.radioAllergy === '0' ? 0 : 1,
      message: this.requestText
    };
    if (this.cartData) {
      for (let i = 0; i < Object.keys(this.cartData).length; i++) {
        cartAddData[Object.keys(this.cartData)[i]] = Object.values(this.cartData)[i];
      }
    }
    if (this.cartAddData) {
      for (let i = 0; i < Object.keys(this.cartAddData).length; i++) {
        // let value = JSON.stringify(Object.values(this.cartAddData)[i]);
        // value = value.replace('[', '');
        // value = value.replace(']', '');
        cartAddData[Object.keys(this.cartAddData)[i]] = Object.values(this.cartAddData)[i];
      }
    }
    if (this.radioChoice === '외국인') {
      cartAddData.country_code = this.countryCode.value;
      cartAddData.email = this.email;
    }
    if (this.radioAllergy === '1' && this.allergyText !== '') {
      cartAddData.customer_allergy_detail = this.allergyText;
    }
    reservationModule
      .fetchCart(cartAddData)
      .then((res) => {
        if (!res.status) {
          this.errorMessage = res.error.message;
          EventBus.$emit('errorMessage', this.errorMessage);
          this.$modal.show('alert');
        } else {
          this.$router.push({ name: 'payment', params: {cartId: res.data.id} });
          document.querySelector('html' as any).classList.remove('open-modal');
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  public addInfoShow(item: any) {
    if (item.title !== '알러지 세부사항') {
      if (item.title === '유아의자' && this.cartData.person_baby === 0) {
        return false;
      }
      return true;
    } else {
      if (
        item.title === '알러지 세부사항' && this.radioAllergy === '1'
      ) {
        return true;
      }
      return false;
    }
  }
  @Watch('allergyText')
  public allergyTextUpdate() {
    this.nextDisableFn();
  }
  @Watch('radioAllergy')
  public radioAllergyUpdate() {
    if (this.radioAllergy === '0' && this.cartAddData[this.allergyKey]) {
      delete this.cartAddData[this.allergyKey];
    }
  }
  @Watch('radioChoice')
  public radioChoiceUpdate() {
    this.requestSendText = '인증번호 발송';
    this.countryCode = '국가번호';
    this.phoneNum = '';
    this.requestNum = '';
    this.requestNumComplete = false;
    this.requestNumExpire = false;
    const el: any = document.querySelector('.certification-area');
    setTimeout(() => {
      this.topElHeight = el.scrollHeight;
    }, 300);
  }
  @Watch('requestComplete')
  public requestCompleteUpdate() {
    if (this.requestComplete) {
      setTimeout(() => {
        this.$refs.scrollWarp.scrollTo(0, this.topElHeight - 140);
      }, 350);
    }
  }
  get requestSendCheck() {
    if (this.requestComplete) {
      return false;
    } else {
      if (this.radioChoice === '내국인' && this.phoneNum !== '' && this.user !== '') {
        return true;
      }
      if (this.radioChoice === '외국인' && this.phoneNum !== '' && this.email !== '' && this.countryCode !== '국가번호' && this.user !== '') {
        return true;
      }
    }
    return false;
  }
  get reservationAddInfo() {
    return reservationModule.reservationAddInfo;
  }
}
